import logo from "./logo.svg";
import "./App.css";
import HomeScreen from "./screens/HomeScreen";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import TextVd from "./screens/TextVd";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/test",
    element: <TextVd />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

import React, { useEffect, useRef, useState } from "react";

function TextVd() {
  const videoRef = useRef(null);
  const scrollSectionRef = useRef(null);
  const [hasLoaded, setLoaded] = useState(false);
  const [imageNumber, setImageNumber] = useState(28);

  useEffect(() => {
    setLoaded(true);
    const playbackConst = 500; // Adjust the constant as needed
    // Use requestAnimationFrame for smooth playback
    function scrollPlay() {
      if (videoRef.current) {
        const frameNumber = window.pageYOffset / playbackConst;
        videoRef.current.currentTime = frameNumber;
      }
      window.requestAnimationFrame(scrollPlay);
    }

    window.requestAnimationFrame(scrollPlay);
    console.log("UE-1");
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    video.addEventListener("loadedmetadata", () => {
      const { duration } = video;
      const playbackConst = 500;
      const scrollSection = scrollSectionRef.current;
      console.log("scrollSection", videoRef.current.duration);
      if (videoRef?.current) {
        scrollSection.style.height =
          Math.floor(duration) * playbackConst + "px";
      }
      console.log(duration); // Output: video duration in seconds
    });

    return () => {
      video.removeEventListener("loadedmetadata", () => {});
    };

    console.log("UE-2");
  }, []);

  return (
    <div className="App">
      <div>
        <video
          className="fixed top-0 h-screen w-screen "
          ref={videoRef}
          id="v0"
          preload="preload"
        >
          <source
            className="h-screen w-screen "
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            // src="https://www.apple.com/media/us/mac-pro/2013/16C1b6b5-1d91-4fef-891e-ff2fc1c1bb58/videos/macpro_main_desktop.mp4"
            src="./pcgreen.mp4"
          ></source>
        </video>
        <div ref={scrollSectionRef} id="scrollSection">
          <div className="absolute  h-screen top-0 z-[90000] drop-shadow scroll-smooth">
            <div id="starting" className="h-screen  text-center ">
              <div className="container mx-auto h-screen  text-center flex flex-col justify-end items-center  text-xl">
                <div className="fixed bottom-12 left-1/2 z-30 transform -translate-x-1/2 mb-10">
                  <div
                    onClick={() => {
                      const element = document.getElementById("reservation");
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                    className={`animate-bounce border border-[#fff] text-[#fff] p-3 rounded-full cursor-pointer ${
                      imageNumber > 3 ? "hidden" : ""
                    } `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className=" h-6 w-6 color-[#fff]"
                      fill="#2f4f4f"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                      />
                    </svg>
                  </div>
                </div>
                <div className="h-14" id="reservation"></div>
              </div>
            </div>
            {/* reservation */}
            <div className=" text-center ">
              <div
                className={`container mx-auto h-screen  text-center flex flex-col justify-center items-center  `}
              >
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div
                  className={`w-full text-left px-2 my-2   ${
                    imageNumber > 32 ? "hidden  h-screen " : ""
                  }`}
                >
                  <div
                    className="md:w-2/3 md:text-xl text-base drop-shadow px-2 "
                    // style={{ textShadow: "0px 1px 11px darkslategrey" }}
                  >
                    <p className="  inline ">
                      Situé au cœur du quartier de l'Hivernage à Marrakech,
                      l'Eden Nightclub offre une expérience inoubliable pour les
                      noctambules en quête de clubbing. En tant que temple de la
                      vie nocturne dans la ville ocre, cet établissement incarne
                      l'essence même de la fête et de la décadence dans un cadre
                      bohemien et captivant.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div
                  className={`px-2 flex flex-row items-start w-full  ${
                    imageNumber > 32 ? "hidden h-screen " : ""
                  }`}
                >
                  <div className="">
                    <a href="https://booking.edenclubmarrakech.com/">
                      <div
                        style={{ textShadow: "0px 1px 11px darkslategrey" }}
                        className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow"
                      >
                        Reservation
                      </div>
                    </a>
                    <div
                      style={{ textShadow: "0px 1px 11px darkslategrey" }}
                      className="text-sm drop-shadow"
                    >
                      Heavenly Vibes, Hellish Beats.
                    </div>
                  </div>
                </div>
                <div className="md:h-20 h-24"></div>
              </div>
            </div>
            <div id="evenements" className="h-screen w-screen text-center ">
              <div className="container mx-auto h-screen   text-center flex flex-col justify-center items-center  text-2xl ">
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div
                  className={`w-full text-left px-2 my-2 
                    ${imageNumber > 54 ? " hidden h-screen" : ""}`}
                >
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="md:w-2/3 md:text-xl text-base drop-shadow  px-2 "
                  >
                    <p className="">
                      Chaque semaine, l'atmosphère électrique est accentuée par
                      la présence de DJ guests de renoms dans ambiance
                      décorative exceptionnelle. Plongez dans un univers
                      bohémien jungle où les murs sont habillés de feuilles
                      d'arbres et de fleurs où chaque détail est pensé pour
                      transporter les clubbers dans un véritable paradis.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div
                  className={`px-2 flex flex-row items-start w-full   ${
                    imageNumber > 54 ? " hidden h-screen" : ""
                  }`}
                >
                  <div className=" px-2">
                    <a href="https://events.edenclubmarrakech.com/">
                      <div
                        style={{ textShadow: "0px 1px 11px darkslategrey" }}
                        className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow"
                      >
                        Nos evenements
                      </div>
                    </a>
                    <div
                      style={{ textShadow: "0px 1px 11px darkslategrey" }}
                      className="text-sm drop-shadow"
                    >
                      Where Heaven and Hell Collide in Exotic Revel
                    </div>
                  </div>
                </div>
                <div className="md:h-20 h-24"></div>
              </div>
            </div>
            <div id="cartes" className="h-screen w-screen text-center ">
              <div className="container mx-auto  h-screen  text-center flex flex-col justify-center items-center  text-2xl ">
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div
                  className={`w-full text-left px-2 my-2   ${
                    imageNumber > 73 ? " hidden h-screen" : ""
                  }`}
                >
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="md:w-2/3 md:text-xl text-base drop-shadow  px-2 "
                  >
                    <p className="">
                      En tant que temple de la vie nocturne dans la ville ocre,
                      cet établissement incarne l'essence même de la fête et de
                      la décadence dans un cadre bohemian captivant.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div
                  className={`px-2 flex flex-row items-start w-full   ${
                    imageNumber > 73 ? " hidden h-screen" : ""
                  }`}
                >
                  <div className=" px-2">
                    <a href="/#">
                      <div
                        style={{ textShadow: "0px 1px 11px darkslategrey" }}
                        className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow"
                      >
                        Découvrez la carte
                      </div>
                    </a>
                    <div
                      style={{ textShadow: "0px 1px 11px darkslategrey" }}
                      className="text-sm drop-shadow"
                    >
                      Our secret, your best night
                    </div>
                  </div>
                </div>
                <div className="md:h-20 h-24"></div>
              </div>
            </div>
            <div id="visites" className="h-screen w-screen text-center ">
              <div className="container mx-auto h-screen  text-center flex flex-col justify-center items-center  text-2xl ">
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div
                  className={`w-full text-left px-2 my-2   ${
                    imageNumber > 96 ? " hidden h-screen" : ""
                  }`}
                >
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="md:w-2/3 md:text-xl text-base drop-shadow  px-2 "
                  >
                    <p className="">
                      L'Eden Nightclub est également équipé d'un matériel
                      dernier cri au niveau de la sonorisation et de
                      l'éclairage, garantissant une qualité sonore et visuelle
                      incomparable. Les systèmes audio haute performance et les
                      jeux de lumières époustouflants ajoutent une dimension
                      sensorielle à chaque soirée, amplifiant ainsi les
                      sensations et les émotions des clubbers.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div
                  className={`px-2 flex flex-row items-start w-full   ${
                    imageNumber > 96 ? " hidden h-screen" : ""
                  }`}
                >
                  <div className=" px-2">
                    <a href="/#">
                      <div
                        style={{ textShadow: "0px 1px 11px darkslategrey" }}
                        className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow"
                      >
                        Visite 360
                      </div>
                    </a>
                    <div
                      style={{ textShadow: "0px 1px 11px darkslategrey" }}
                      className="text-sm drop-shadow"
                    >
                      Unleash the wild, Embrace the Night
                    </div>
                  </div>
                </div>
                <div className="md:h-20 h-24"></div>
              </div>
            </div>
            <div id="contact-us" className="h-screen w-screen text-center ">
              <div className="container mx-auto h-screen  text-center flex flex-col justify-center items-center  text-2xl ">
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div className="w-full text-left mx-2">
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="md:w-2/3 md:text-xl text-base drop-shadow  px-2 "
                  >
                    <p className="">
                      Contactez-nous pour reserver votre table ou afin plus
                      d’informations concernant nos evenements
                    </p>
                  </div>
                </div>
                {/*  */}
                <div className={`px-2 flex flex-row items-start w-full `}>
                  <div className="">
                    <a href="http://booking.edenclubmarrakech.com/contact">
                      <div
                        style={{ textShadow: "0px 1px 11px darkslategrey" }}
                        className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow"
                      >
                        Contactez-nous
                      </div>
                    </a>
                    <div></div>
                  </div>
                </div>
                <div className="md:h-16 h-16"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextVd;
